"use client"

import { useDraftModeEnvironment } from "next-sanity/hooks"
import Link from "next/link"

export function DisableDraftMode() {
  const environment = useDraftModeEnvironment()

  // Only show the disable draft mode button when outside of Presentation Tool
  if (environment !== "live" && environment !== "unknown") {
    return null
  }

  return (
    <Link
      href="/api/draft-mode/disable"
      className="fixed bottom-4 right-4 bg-gray-50 px-4 py-2"
      prefetch={false}
    >
      Disable Draft Mode
    </Link>
  )
}

export function EnableDraftMode() {
  const environment = useDraftModeEnvironment()
  if (environment !== "live" && environment !== "unknown") {
    return null
  }

  return (
    <Link
      href="/api/draft-mode/enable"
      className="fixed bottom-4 right-4 bg-gray-50 px-4 py-2"
      prefetch={false}
    >
      Enable Draft Mode
    </Link>
  )
}

export function ToggleDraftMode({ isDraftMode }: { isDraftMode: boolean }) {
  return isDraftMode
    ? (
      <DisableDraftMode />
    ) : (
      <EnableDraftMode />
    )
}